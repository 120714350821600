import { Link } from "gatsby"
import React from "react"

import { footerMenu, glossaryMenu } from "../helpers/menus"

const Footer = () => (
    <footer className="container footer">
        <div className="row align-items-center">
            <div className="col-12 col-lg-auto">
                © {new Date().getFullYear()} Berger &amp; Schnickmann
            </div>

            <div className="col-12 col-lg-auto ml-xl-auto">
                <ul className="list-unstyled footer-menu">
                    {footerMenu.map(link => {
                        return (
                            <li key={link.path}>
                                <Link to={link.path} activeClassName="active">
                                    {link.title}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <div className="col-12 mt-3 ml-xl-auto text-xl-right">
                <span>{glossaryMenu.title}:</span>
                {glossaryMenu.children.map((link, index) => {
                    return (
                        <span key={link.path} className="glossary-link">
                            <Link to={link.path} className="ml-1" activeClassName="font-weight-bold">
                                {link.title}
                            </Link>{ (index !== glossaryMenu.children.length -1) ? ', ' : '' }
                        </span>
                    )
                })}
            </div>
        </div>
    </footer>
)

export default Footer
