export const mainMenu = [
    {
        title: "Bildungsblog",
        path: "/bildungsblog/",
    },
    {
        title: "Berufskolleg",
        children: [
            {
                title: "Suche von Schulnummern in NRW",
                path: "/berufskolleg/schulnummersuche/",
            },
        ],
    },
    {
        title: "Informatik",
        children: [
            {
                title: "Grundlagen",
                children: [
                    {
                        title: "Rechenoperationen",
                        path: "/informatik/grundlagen/rechenoperationen/",
                    },
                    {
                        title: "Division mit Rest",
                        path: "/informatik/grundlagen/division-mit-rest/",
                    },
                    {
                        title: "Stellenwertsysteme",
                        path: "/informatik/grundlagen/stellenwertsysteme/",
                    },
                ],
            },
            {
                title: "Speichereinheiten",
                path: "/informatik/speichereinheiten/",
            },
            {
                title: "Codierungen",
                path: "/informatik/codierungen/",
                children: [
                    {
                        title: "RGB-Farb-Code",
                        path: "/informatik/codierungen/rgb-farb-code/",
                    },
                    {
                        title: "ASCII",
                        path: "/informatik/codierungen/ascii/",
                    },
                    {
                        title: "Morse-Code",
                        path: "/informatik/codierungen/morse-code/",
                    },
                    {
                        title: "Huffman-Code",
                        path: "/informatik/codierungen/huffman-code/",
                    },
                ],
            },
            {
                title: "Symmetrische Verschlüsselung",
                path: "/informatik/symmetrische-verschluesselung/",
                children: [
                    {
                        title: "Cäsar-Chiffre",
                        path: "/informatik/symmetrische-verschluesselung/caesar-chiffre/",
                    },
                    {
                        title: "Vigenère-Quadrat",
                        path: "/informatik/symmetrische-verschluesselung/vigenere-quadrat/",
                    },
                    {
                        title: "Spaltentransposition",
                        path:
                            "/informatik/symmetrische-verschluesselung/spaltentransposition/",
                    },
                ],
            },
            {
                title: "Asymmetrische Verschlüsselung",
                path: "/informatik/asymmetrische-verschluesselung/",
                children: [
                    {
                        title: "Modulo-Potenzrechnung",
                        path:
                            "/informatik/asymmetrische-verschluesselung/modulo-potenzrechnung/",
                    },
                    {
                        title: "Diffie-Hellman-Schlüsselaustausch",
                        path:
                            "/informatik/asymmetrische-verschluesselung/diffie-hellman-schluesselaustausch/",
                    },
                    {
                        title: "Euklidischer Algorithmus",
                        path:
                            "/informatik/asymmetrische-verschluesselung/euklidischer-algorithmus/",
                    },
                    {
                        title: "Erweiterter Euklidischer Algorithmus",
                        path:
                            "/informatik/asymmetrische-verschluesselung/erweiterter-euklidischer-algorithmus/",
                    },
                    {
                        title: "RSA",
                        path: "/informatik/asymmetrische-verschluesselung/RSA/",
                    },
                ],
            },
        ],
    },
    {
        title: "Mathematik",
        children: [
            {
                title: "Koordinatensystem Generator",
                path: "/mathematik/koordinatensystem-generator/",
            },
        ],
    },
    {
        title: "Sitemap",
        path: "/sitemap/",
    },
]

export const footerMenu = [
    {
        title: "Sitemap",
        path: "/sitemap/",
    },
    {
        title: "Datenschutz",
        path: "/datenschutz",
    },
    {
        title: "Impressum",
        path: "/impressum",
    },
]

export const glossaryMenu = {
    title: "Glossare",
    children: [{
        title: "Apps",
        path: "/glossar-zu-apps/",
    }, {
        title: "Fortbildungen",
        path: "/glossar-zu-fortbildungen/",
    }, {
        title: "Berufliche Bildung im digitalen Wandel",
        path: "/glossar-zur-beruflichen-bildung-im-digitalen-wandel/",
    }]
};