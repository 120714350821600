import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { mainMenu } from "../helpers/menus"
import { renderMenu } from "../helpers/renderMenu"

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuIsOpen: false,
      appIsMounted: false,
    }

    this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this)
  }

  componentDidMount() {
    if (!this.refs["main-menu"] || typeof global.MmenuLight !== "function") {
        return;
    }

    const menu = new global.MmenuLight(this.refs["main-menu"]);
    this.setState({
        appIsMounted: true,
    })

    this.navigator = menu.navigation({
      title: "Menü",
    })

    this.drawer = menu.offcanvas({
      position: "right",
    });
  }

  menuButtonClickHandler() {
    this.drawer.open();
  }

  renderDesktopMenu(items, level = 0) {
    return (
      <ul className={`desktop-menu desktop-menu--level-${level}`}>
        {items.map((link) => {
          return (
            <li key={link.path || link.title}>
              {link.path && <Link to={link.path}>{link.title}</Link>}
              {!link.path && <span>{link.title}</span>}
              {link.children &&
                link.children.length &&
                this.renderDesktopMenu(link.children, level + 1)}
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    return (
      <header className="header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-auto">
              <Link to="/">{this.props.siteTitle}</Link>
            </div>
            <div className="col-auto ml-auto">
              {mainMenu && mainMenu.length && this.renderDesktopMenu(mainMenu)}
            </div>
            <div className="col-auto ml-auto d-lg-none">
              <button
                type="button"
                className="btn"
                onClick={this.menuButtonClickHandler}
              >
                <svg
                  className="toggle-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="768"
                  height="768"
                  viewBox="0 0 768 768"
                >
                  <path d="M96 192h576v64.5H96V192zm0 223.5v-63h576v63H96zM96 576v-64.5h576V576H96z" />
                </svg>
              </button>
            </div>
            <div ref="main-menu" className="mobile-menu">
              {mainMenu && mainMenu.length && renderMenu(mainMenu)}
            </div>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
