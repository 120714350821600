import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { glossaryMenu, mainMenu } from "../helpers/menus"
import { renderMenu } from "../helpers/renderMenu"

class Page extends React.Component {
    constructor(props) {
        super(props);

        const menu = JSON.parse(JSON.stringify(mainMenu));
        menu.splice(menu.length - 1, 0, glossaryMenu);

        this.state = {
            menu,
        }
    }

    render() {
        return (
            <Layout title="Sitemap">
                <SEO title="Sitemap" />

                {renderMenu(this.state.menu)}
            </Layout>
        )
    }
}

export default Page
