import React from "react"
import { Link } from "gatsby"
import { globalHistory } from "@reach/router"
import classnames from "classnames"

export function renderMenu(items) {
  return (
    <ul>
      {items.map(link => {
        const classes = classnames({
          Selected: globalHistory.location.pathname === link.path,
        })

        return (
          <li key={link.path || link.title} className={classes}>
            {link.path && <Link to={link.path}>{link.title}</Link>}
            {!link.path && <span>{link.title}</span>}
            {link.children && link.children.length && renderMenu(link.children)}
          </li>
        )
      })}
    </ul>
  )
}
